<template>
  <div>
    <div class="flex items-center w-full p-2 gap-1 bg-theme-primary">
      <p class="leading-[28px] text-[20px] font-bold">{{ $t('spots.filter') }}</p>
      <button
        v-if="!isEmpty()"
        class="flex items-center gap-0.5 transition-opacity duration-100 hover:text-theme-secondary text-xs"
        @click="emptyAllFilters"
      >
        <img class="w-[10px] h-[10px]" src="~assets/icon/close.svg"
             :alt="t('spots.filter_delete_all_filter_button_title')" />{{
          $t('spots.filter_delete_all_filter_button_title')
        }}
      </button>
      <button class="ml-auto" @click="$emit('close')">
        <img src="~/assets/icon/close.svg" :alt="t('accessibility.close')" />
      </button>
    </div>
    <ErrorMessage v-if="errorMessage" :message="errorMessage"
                  :small="true" class="mb-2" />
    <div v-else class="flex flex-col bg-theme-primary text-theme-font gap-2 p-2">
      <!-- Type -->
      <div>
        <div class="flex items-end">
          <p class="grow uppercase">{{ $t('spots.filter_title_type') }}</p>
          <FilterBarDeleteButton
            :visible="selectedType != undefined"
            @click="emptySelectedType()"
          />
        </div>
        <hr class="h-px bg-theme-secondary bg-opacity-30 border-0" />
        <div class="flex border-[1px] border-black rounded-lg divide-black">
          <input
            class="hidden"
            type="radio"
            id="type-filter-none"
            name="type-filter"
            v-model="selectedType"
            :value="undefined"
            @change="loadNumberOfResults"
          />
          <input
            class="hidden peer/fame"
            type="radio"
            id="type-filter-fame"
            name="type-filter"
            v-model="selectedType"
            :value="'fame'"
            @change="loadNumberOfResults"
          />
          <label
            class="cursor-pointer rounded-l-[6px] grow text-center p-0.5 peer-checked/fame:bg-black peer-checked/fame:text-white"
            for="type-filter-fame"
          >{{ $t('spots.filter_text_fame') }}</label
          >
          <input
            class="hidden peer/shame"
            type="radio"
            id="type-filter-shame"
            name="type-filter"
            v-model="selectedType"
            :value="'shame'"
            @change="loadNumberOfResults"
          />
          <label
            class="cursor-pointer border-l-black border-l-[1px] rounded-r-[6px] border-black grow text-center p-0.5 peer-checked/shame:bg-black peer-checked/shame:text-white"
            for="type-filter-shame"
          >{{ $t('spots.filter_text_shame') }}</label
          >
        </div>
      </div>
      <!-- Fixed -->
      <div v-if="selectedType != 'fame'">
        <div class="flex items-end">
          <p class="grow uppercase">{{ $t('spots.filter_text_fixed') }}</p>
          <FilterBarDeleteButton
            :visible="fixed != undefined"
            @click="emptyFixed()"
          />
        </div>
        <hr class="h-px bg-theme-secondary bg-opacity-30 border-0" />
        <div class="flex border-[1px] border-black rounded-lg divide-black">
          <input
            class="hidden"
            type="radio"
            id="fixed-filter-undefined"
            name="fixed-filter"
            v-model="fixed"
            :value="undefined"
            @change="loadNumberOfResults"
          />
          <input
            class="hidden peer/true"
            type="radio"
            id="fixed-filter-true"
            name="fixed-filter"
            v-model="fixed"
            :value="true"
            @change="loadNumberOfResults"
          />
          <label
            class="cursor-pointer rounded-l-[6px] grow text-center p-0.5 peer-checked/true:bg-black peer-checked/true:text-white"
            for="fixed-filter-true"
          >{{ $t('spots.filter_text_fixed_value') }}</label
          >
          <input
            class="hidden peer/false"
            type="radio"
            id="fixed-filter-false"
            name="fixed-filter"
            v-model="fixed"
            :value="false"
            @change="loadNumberOfResults"
          />
          <label
            class="cursor-pointer border-l-black border-l-[1px] rounded-r-[6px] border-black grow text-center p-0.5 peer-checked/false:bg-black peer-checked/false:text-white"
            for="fixed-filter-false"
          >{{ $t('spots.filter_text_not_fixed_value') }}</label
          >
        </div>
      </div>

      <!-- Official Answer -->
      <div>
        <div class="flex items-end">
          <p class="grow uppercase">{{ $t('spots.filter_text_official_answer') }}</p>
          <FilterBarDeleteButton
            :visible="officialAnswer != undefined"
            @click="emtpyOfficialAnswer()"
          />
        </div>
        <hr class="h-px bg-theme-secondary bg-opacity-30 border-0" />
        <div class="flex border-[1px] border-black rounded-lg divide-black">
          <input
            class="hidden"
            type="radio"
            id="officialanswer-filter-undefined"
            name="officialanswer-filter"
            v-model="officialAnswer"
            :value="undefined"
            @change="loadNumberOfResults"
          />
          <input
            class="hidden peer/true"
            type="radio"
            id="officialanswer-filter-true"
            name="officialanswer-filter"
            v-model="officialAnswer"
            :value="true"
            @change="loadNumberOfResults"
          />
          <label
            class="cursor-pointer rounded-l-[6px] grow text-center p-0.5 peer-checked/true:bg-black peer-checked/true:text-white"
            for="officialanswer-filter-true"
          >{{ $t('spots.filter_text_officialanswer_value') }}</label
          >
          <input
            class="hidden peer/false"
            type="radio"
            id="officialanswer-filter-false"
            name="officialanswer-filter"
            v-model="officialAnswer"
            :value="false"
            @change="loadNumberOfResults"
          />
          <label
            class="cursor-pointer border-l-black border-l-[1px] rounded-r-[6px] border-black grow text-center p-0.5 peer-checked/false:bg-black peer-checked/false:text-white"
            for="officialanswer-filter-false"
          >{{ $t('spots.filter_text_not_officialanswer_value') }}</label
          >
        </div>
      </div>

      <!-- Category -->
      <div>
        <div v-if="categories && categories.length" class="flex gap-0.5">
          <p class="grow uppercase line">{{ $t('spots.filter_title_category') }}</p>
          <FilterBarDeleteButton
            :visible="selectedCategorieIds.length > 0"
            @click="emptySelectedCategorieIds()"
          />
        </div>
        <hr class="h-px bg-theme-secondary bg-opacity-30 border-0" />
        <div class="flex flex-wrap gap-0.5">
          <div v-for="category in categories" :key="category.id">
            <input
              type="checkbox"
              class="peer appearance-none"
              :id="category.id.toString()"
              :value="category.id"
              v-model="selectedCategorieIds"
              @change="loadNumberOfResults"
            />
            <label
              :for="category.id.toString()"
              class="peer-checked:bg-black peer-checked:text-white transition-all duration-75 cursor-pointer px-1 py-[2px] uppercase text-black border-black border-solid border-[1px] rounded-[20px] text-[12px]"
            >{{ category.name }}</label
            >
          </div>
        </div>
      </div>
      <!-- Show results button -->
      <div class="flex items-end justify-center">
        <ButtonSecondary
          v-if="!loading"
          class="grow"
          @click="$emit('submit')"
        >{{ numberOfResults + ' Spots' }}
        </ButtonSecondary
        >
        <Loading v-if="loading" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  regionId?: number;
}>();
defineEmits(['submit', 'close']);

const platformSettings = usePlatformSettings();

const selectedCategorieIds = ref<Array<number>>([]);

const selectedType = platformSettings.filterType;
const fixed = platformSettings.filterFixed;
const officialAnswer = platformSettings.filterOfficialAnswer;

const loading = ref<boolean>();
const errorMessage = ref<string | undefined>(undefined);
const initialParams = platformSettings.getFilterParams();
const { t } = useI18n();



const { data: categories, error: cateogoriesError } = await useApi().fetch<Array<Category>>('categories');
const {data: numberOfResults } = await useApi().fetch<number>('spotPreviewsFilterResults', {query: initialParams});

if (categories.value == null) {
  categories.value = [];
}

if (cateogoriesError.value) {
  errorMessage.value = 'generic';
}

watch(() => props.regionId, () => {
  loadNumberOfResults();
});


function emptyAllFilters() {
  selectedCategorieIds.value = [];
  selectedType.value = undefined;
  fixed.value = undefined;
  officialAnswer.value = undefined;
  loadNumberOfResults();
}

function emptySelectedCategorieIds() {
  selectedCategorieIds.value = [];
  loadNumberOfResults();
}

function emptySelectedType() {
  selectedType.value = undefined;
  loadNumberOfResults();
}

function emptyFixed() {
  fixed.value = undefined;
  loadNumberOfResults();
}

function emtpyOfficialAnswer() {
  officialAnswer.value = undefined;
  loadNumberOfResults();
}

function isEmpty() {
  return (
    selectedType.value == undefined &&
    fixed.value == undefined &&
    selectedCategorieIds.value.length == 0
  );
}

function loadNumberOfResults() {
  loading.value = true;
  const params = platformSettings.getFilterParams();

  useApi().get<number>('spotPreviewsFilterResults', { params })
    .then((response) => {
      numberOfResults.value = response;
      loading.value = false;
    });
}

const setFilters = () => {
  const filters = platformSettings.getFilterParams();
  if (!filters) {
    selectedType.value = undefined;
    fixed.value = undefined;
    selectedCategorieIds.value = [];
  } else {
    selectedType.value = filters.famed ? 'fame' : filters.famed === false ? 'shame' : undefined;
    fixed.value = filters.fixed;
    selectedCategorieIds.value = filters.categoryIds ? filters.categoryIds : [];
  }
  loadNumberOfResults();
};

defineExpose({
  setFilters
});

</script>
<style lang="postcss" scoped>
hr {
  @apply mb-1;
}

select {
  @apply w-full appearance-none border-solid border-[1px] border-black rounded-[7px] p-0.5;

  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='25' height='15' stroke='black' stroke-width='1' fill='none'><path d='M5 5 L10 10 15 5' /></svg>");
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-position: right center;
}
</style>
